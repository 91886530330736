import React from 'react';

function ExperienceDiv(props) {
    return (
      <div className='project'>
        <div style={{ marginRight: '20px' }}>
          <img src={props.imageSrc} alt={props.imageAlt} style={{ width: '150px', height: '84px' }} />
        </div>
        <div>
          <h2>{props.title}</h2>
          <p>{props.year}</p>
          <p>{props.description}</p>
        </div>
      </div>
    );
  }

export default ExperienceDiv;