import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import ProjectDiv from './components/ProjectDiv';
import SkillDiv from './components/SkillDiv';
import ExperienceDiv from './components/ExperienceDiv';

const App = () => {

  return (
    // <div>
    //   <Navbar />
    //   <section id="home" class="name_banner">
    //     <div className="center-container"
    //     style = {{backgroundImage: 'url("https://www.alfalaval.com/globalassets/images/products/separation/centrifugal-separators/separators/separators-for-chemical-and-manufacturing-industries/connected-service_1920x480.png")'}}
    //     >
    //       <img src = './images/headshot_cf.jpeg'
    //         alt="Connor"
    //         style={{ width: '200px', height: '202px'}} className = "image-padding"/>

    //       <p style = {{backgroundColor: "#0f8c88", color: 'white', marginTop: 0}}>
    //         Hi, my name is Connor Ferris! I'm a computer science student at Iowa State University. <br />
    //         I expect to graduate in May 2025. <br />
    //       </p>
    //     </div>
    //   </section>

    //   <section id="projects">
    //     <div className='sectionTitle'>
    //       <h1>Projects</h1>
    //     </div>
    //     <ProjectDiv
    //       imageSrc = './images/servington.png'
    //       imageAlt = 'Servington'
    //       title = 'Servington'
    //       description = 'Android app project focused on community service'
    //     />
    //     <ProjectDiv
    //       imageSrc = './images/pikachu.jfif'
    //       imageAlt = 'Pokemon'
    //       title = 'C++ Pokemon Game'
    //       description = 'Roguelike game made from scratch with C/C++ using Pokemon data'
    //     />
    //     <ProjectDiv
    //       imageSrc = './images/gambling_sim.jpg'
    //       imageAlt = 'Gambling Sim'
    //       title = 'Gambling Simulator'
    //       description = 'Roobet Mines gambling game built in Unity game engine'
    //     />
    //   </section>

    //   <section id="skills">
    //   <div className='sectionTitle'>
    //       <h1>Skills</h1>
    //     </div>
    //     <div className='project' style={{ justifyContent: 'center' }}>
    //       <SkillDiv
    //         imageSrc = './images/skills/html_css_js.png'
    //         imageAlt = 'html,css,js'
    //         name = 'HTML, CSS, JavaScript'
    //       />
    //       <SkillDiv
    //         imageSrc = './images/skills/react.png'
    //         imageAlt = 'react'
    //         name = 'React'
    //       />
    //       <SkillDiv
    //         imageSrc = './images/skills/c_sharp.png'
    //         imageAlt = 'c#'
    //         name = 'C#'
    //       />
    //       <SkillDiv
    //         imageSrc = './images/skills/c++.png'
    //         imageAlt = 'c++'
    //         name = 'C++'
    //       />
    //       <SkillDiv
    //         imageSrc = './images/skills/android_studio.png'
    //         imageAlt = 'android'
    //         name = 'Android Studio'
    //       />
    //       <SkillDiv
    //         imageSrc = './images/skills/java.png'
    //         imageAlt = 'java'
    //         name = 'Java'
    //       />
    //       <SkillDiv
    //         imageSrc = './images/skills/sql.png'
    //         imageAlt = 'sql'
    //         name = 'SQL'
    //       />
    //     </div>
    //   </section>

    //   <section id="experience">
    //     <div className='sectionTitle'>
    //       <h1>Experience</h1>
    //     </div>
    //     <ExperienceDiv
    //         imageSrc = './images/database_usa.jpg'
    //         imageAlt = 'database_usa'
    //         year = 'Summer 2022'
    //         title = 'DatabaseUSA - Data Analyst / Developer Intern'
    //         description = 'Created Alteryx ETL projects to find issues within databases and make efficient, large-scale modifications. Coordinated with other developers to evaluate and improve routine database processes. Cleaned and formatted web scrape files.'
    //       />
    //     <ExperienceDiv
    //       imageSrc = './images/iowa_state.jpg'
    //       imageAlt = 'isu'
    //       year = 'Fall 2022'
    //       title = 'Iowa State University - COMS 227 Teaching Assistant'
    //       description = 'Oversaw weekly labs teaching students fundamentals of Java programming. Held office hours to answer student questions for programming assignments.'
    //     />
    //     <ExperienceDiv
    //       imageSrc = './images/fcsa.jpg'
    //       imageAlt = 'isu'
    //       year = 'Summer 2024'
    //       title = 'Farm Credit Services of America - Application Developer Intern'
    //       description = ''
    //     />
    //   </section>

    //   <section id="more">
    //     <div className='sectionTitle'>
    //       <h1>Other Involvement</h1>
    //     </div>

    //     <ProjectDiv
    //       imageSrc = './images/delta_chi.jpg'
    //       imageAlt = 'Delta Chi'
    //       title = 'Delta Chi Fraternity'
    //       description = 'Throughout my time at Iowa State I have been heavily involved in the Delta Chi Fraternity. During the calendar year of 2023 I served as chapter president.'
    //     />

    //   </section>

    //   <section id="contact">
    //     <div className='sectionTitle'>
    //       <h1>Contact</h1>
    //     </div>

    //     <div className='project' style = {{justifyContent: 'center', flexDirection: "column"}}>
    //       <p>connor27@iastate.edu</p>

    //       <a href="https://www.linkedin.com/in/connor-ferris/" target="_blank" rel="noopener noreferrer">
    //         LinkedIn
    //       </a>

    //       <a href="https://github.com/cferris527" target="_blank" rel="noopener noreferrer">
    //         GitHub
    //       </a>
    //     </div>

    //   </section>

    //   <footer>
    //     <p>2023 Connor Ferris</p>
    //   </footer>


    // </div>
    <p>Come back soon...</p>
  );
}

export default App;
